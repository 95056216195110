@import url(https://cdn.jsdelivr.net/gh/sdip15fa/login-page-css@f8006cd/src/03-google/style.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css);
@import url(https://cdn.jsdelivr.net/gh/sdip15fa/buttons/main.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.1.3/css/bootstrap.min.css);


.alertmessage {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 20px;
}

.userbtn {
  margin-top: 10px;
  margin-right: 10px;
}

.editor,
.createbtn,
.link {
  margin-left: 20px;
  margin-right: 20px;
}

a {
  border-bottom: 0px;
}

